<style>
  .vue-notification {
    font-size:16px !important;
  }
  .my-notification {
    margin-top:40px;
  }
  .vue-notification.warn {
    color: #000 !important;
    background: #ffecd1 !important;
    border-left-color: #ffb648 !important;
  }
  .vue-notification.failed {
    color: #000 !important;
    background: #f8d5d3 !important;
    border-left-color: #E54D42 !important;
  }
  .vue-notification.normal {
    color: #000 !important;
    background: #d9f8e2 !important;
    border-left-color: #68CD86 !important;
  }
</style>

<template>
  <v-app>
    <v-app-bar
      app
      color="bgBar"
    >

      <about-dialog :version=version></about-dialog>

      <help-dialog
        :today="today"
        :options="options"
      >
      </help-dialog>

      <v-spacer></v-spacer>

      <h1 class="text-center">
        <p class="text-h5 font-weight-black ma-0">
          市<span class="wordleGreen--text">区</span>町村
        </p>
        <p class="text-body-2 ma-0">
          <span class="d-none d-sm-inline"
            >Shi <span class="wordleGreen--text">Ku</span> Cho Son - </span
          >Municipalities of Japan.
        </p>
      </h1>

      <v-spacer></v-spacer>

      <stat-dialog v-bind:records="records"> </stat-dialog>

      <options-dialog
        v-bind:options="options"
        @unit="setUnit"
        @theme="setTheme"
      >
      </options-dialog>
    </v-app-bar>

    <v-main>
      <map-container :code="correctCode" :item="correctItem"> </map-container>

      <v-container style="max-width: 420px" class="text-center">
        <answer-row
          v-for="answer in answers"
          v-bind:key="answer.code"
          :today="today"
          :options="options"
          :answer="answer"
        >
        </answer-row>

        <empty-row
          v-for="n in 6 - answers.length"
          v-bind:key="n"
        >
        </empty-row>

        <v-row v-if="isGuessEnable()">
          <v-col cols="12" class="pa-0">
            <v-autocomplete
              :items="shikuchoson"
              :item-text="
                (item) =>
                  item.state +
                  ' ' +
                  item.name +
                  ' (' +
                  item.state_ruby +
                  ' ' +
                  item.ruby +
                  ')'
              "
              item-value="code"
              label="市区町村"
              outlined
              class="pt-5 mb-0"
              v-model="answerCode"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-btn
              class="mt-n4 mb-5 align-center"
              fab
              dark
              large
              color="btnGuess"
              @click="guess"
            >
              <v-icon>mdi-map-marker-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="">
            <v-btn block @click="copyToClipboard">
              <v-icon class="me-1">mdi-share-variant-outline</v-icon>
              結果をシェアする
            </v-btn>
          </v-col>
          <v-col cols="12" class="pb-1">
            <v-btn
              :href="googleMapUrl"
              target="_blank"
              block
              color="info"
              style="text-transform: none"
            >
              <v-icon class="me-1">mdi-map-marker-question-outline</v-icon>
              Google Mapで確認する
            </v-btn>
          </v-col>
          <v-col cols="12" class="">
            <v-btn
              :href="wikipediaUrl"
              target="_blank"
              block
              color="info"
              style="text-transform: none"
            >
              <v-icon class="me-1">mdi-wikipedia</v-icon>
              Wikipediaで確認する
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <faq-container></faq-container>

    <rakuten-container></rakuten-container>

    <my-footer :version=version></my-footer>

    <notifications
      group="default"
      position="top center"
      width="360"
      class="my-notification"
    ></notifications>
  </v-app>
</template>

<script>
import LatLon from "geodesy/latlon-spherical.js";
import dayjs from "dayjs";
import seedrandom from "seedrandom";

import shikuchoson from "@/assets/shikuchoson.json";
import MapContainer from "./components/MapContainer.vue";
import AnswerRow from "./components/AnswerRow.vue";
import EmptyRow from "./components/EmptyRow.vue";
import AboutDialog from "./components/AboutDialog.vue";
import HelpDialog from "./components/HelpDialog.vue";
import OptionsDialog from "./components/OptionsDialog.vue";
import StatDialog from "./components/StatDialog.vue";
import MyFooter from './components/MyFooter.vue';
import FaqContainer from './components/FaqContainer.vue';
import RakutenContainer from './components/RakutenContainer.vue';

export default {
  components: {
    "map-container": MapContainer,
    "my-footer": MyFooter,
    "answer-row": AnswerRow,
    "empty-row": EmptyRow,
    "about-dialog": AboutDialog,
    "help-dialog": HelpDialog,
    "stat-dialog": StatDialog,
    "options-dialog": OptionsDialog,
    "faq-container": FaqContainer,
    "rakuten-container": RakutenContainer,
  },
  data: () => ({
    version: require('../package.json').version,
    options: {
      unit: "km",
      theme: false,
    },
    records: {
      version: require('../package.json').version,
      challenge: 0,
      win: 0,
      streak: 0,
      maxStreak: 0,
      trial: [0, 0, 0, 0, 0, 0],
      today: null,
      codes: [],
      correct: false,
    },
    today: null,
    todayNo: 0,
    shikuchoson: shikuchoson,
    correctCode: 0,
    correctItem: undefined,
    googleMapUrl: "",
    wikipediaUrl: "",
    answerCode: 0,
    icons: ["mdi-twitter"],
    answers: [],
  }),
  mounted() {
    this.loadRecords()
  },
  async created() {
    console.log("ShiKuChoSon: " + this.version)

    this.loadOptions()
    this.today = dayjs(this.jstDate())

    // サービス開始から今日までのコードをログへ出力
    /*
    var current = dayjs("2022-04-03", "YYYY-MM-DD")
    var pastCodes = []
    while (current.isBefore(this.today)) {
      // この日の番号と市区町村コード
      const dayNo = this.getDayNo(current)
      const dayCode = this.getDayCode(current)
      // 市区町村からcodeを検索
      const item = shikuchoson.find((item) => item.code == dayCode);
      if (item === undefined) {
        current = current.add(1, 'day')
        continue;
      }

      // dayCodeが過去と被っているかチェックする
      if (pastCodes.indexOf(dayCode) != -1) {
        console.log("#" + dayNo +","+ dayCode +","+ item.state +","+ item.name +",再出")
      }
      else {
        console.log("#" + dayNo +","+ dayCode +","+ item.state +","+ item.name )
        pastCodes.push(dayCode)
      }

      current = current.add(1, 'day')
    }
    */

    this.todayNo = this.getDayNo(this.today)
    this.correctCode = this.getDayCode(this.today)

    this.correctItem = shikuchoson.find(
      (item) => item.code == this.correctCode
    )
    this.googleMapUrl = encodeURI(
      "https://www.google.com/maps/place/" + this.correctItem.state + this.correctItem.name
    )
    this.wikipediaUrl = encodeURI(
      "https://ja.wikipedia.org/wiki/" + this.correctItem.name
    )   
  },
  methods: {
    jstDate() {
      return new Date(
        Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000
      )
    },
    getDayNo(today) {
      const from = dayjs("2022-04-03", "YYYY-MM-DD")
      return today.diff(from, "days") + 1
    },
    getDayCode(today) {
      const pastDays = 300

      // 過去(最大)300日前から今日までの設問をリストアップ
      var pastIndices = []
      var dayno = this.getDayNo(today)
      for (var i=dayno-1; i >= 0; --i) {
        // i日前
        var past = today.subtract(i, 'days')
        var pastRng = seedrandom.alea(past.format("YYYY-MM-DD"))

        // 過去と被らなインデックスをリストアップする
        var pastIndex = 0;
        do {
          pastIndex = Math.floor(pastRng() * shikuchoson.length)
        } while (pastIndices.indexOf(pastIndex) != -1)
        pastIndices.push(pastIndex)

        // 300を越えたら先頭から除去
        if (pastIndices.length > pastDays) {
          pastIndices.shift()
        }
      }

      var todayIndex = pastIndices.at(-1)
      return shikuchoson[todayIndex].code
    },
    setUnit(unit) {
      this.options.unit = unit;
      this.saveOptions();
    },
    setTheme(theme) {
      this.options.theme = theme;
      this.$vuetify.theme.dark = this.options.theme;
      this.saveOptions();
    },
    isGuessEnable() {
      return this.records.codes.length < 6 && !this.records.correct;
    },
    guess() {
      const code = this.answerCode;
      this.answerCode = undefined;

      const result = this.guessCode(code);
      if (result === "duplicate") {
        this.$notify({
          group: "default",
          type: "warn",
          text: "🤔その市区町村は回答済みです",
        });
        return;
      } else if (result === "unknown") {
        this.$notify({
          group: "default",
          type: "warn",
          text: "🤔その市区町村はわかりません",
        });
        return;
      }

      // 回答を追加
      this.records.codes.push(code);
      // 初回回答で参戦回数をインクリメント
      if (this.answers.length == 1) {
        this.records.challenge++;
      }

      if (result === true) {
        this.$notify({
          group: "default",
          type: "normal",
          text: "正解🎉",
          duration: -1,
        });

        // 正答後処理
        this.records.correct = true;
        this.records.win++;
        this.records.streak++;
        if (this.records.maxStreak < this.records.streak) {
          this.records.maxStreak = this.records.streak;
        }
        this.records.trial[this.answers.length - 1]++;
      } else if (this.answers.length == 6) {
        this.$notify({
          group: "default",
          type: "warn",
          text:
            "答えは、" +
            this.correctItem.state +
            this.correctItem.name +
            " でした",
          duration: -1,
        });
      }

      this.records.today = this.today.format("YYYY-MM-DD");
      this.saveRecords();
    },
    guessCode(code) {
      // 市区町村からcodeを検索
      const item = shikuchoson.find((item) => item.code == code);
      if (item === undefined) {
        return "unknown";
      }

      // 回答済みと被ってる
      const answer = this.answers.find((item) => item.code == code);
      if (answer !== undefined) {
        return "duplicate";
      }

      if (this.correctCode == code) {
        this.answers.push({
          is_correct: true,
          is_correct_state: true,
          code: code,
          state: item.state,
          name: item.name,
          dist: 0,
          dir: 361,
        })

        return true
      } else {
        const from = new LatLon(item.lat, item.lng)
        const to = new LatLon(this.correctItem.lat, this.correctItem.lng)
        const dist = Math.floor(from.distanceTo(to) / 1000)
        const fb = Math.floor(from.finalBearingTo(to))

        const is_correct_state = item.state === this.correctItem.state

        this.answers.push({
          is_correct: false,
          is_correct_state: is_correct_state,
          code: code,
          state: item.state,
          name: item.name,
          dist: dist,
          dir: fb,
        })

        return false
      }
    },
    loadOptions() {
      // ローカルストレージから読み込み
      if (localStorage.getItem("options")) {
        try {
          this.options = JSON.parse(localStorage.getItem("options"));
        } catch (e) {
          localStorage.removeItem("options");
        }

        this.$vuetify.theme.dark = this.options.theme;
      }
    },
    saveOptions() {
      // ローカルストレージへ保存
      const parsedOptions = JSON.stringify(this.options);
      localStorage.setItem("options", parsedOptions);
    },
    loadRecords() {
      // ローカルストレージから読み込み
      if (localStorage.getItem("records")) {
        try {
          this.records = JSON.parse(localStorage.getItem("records"));
        } catch (e) {
          localStorage.removeItem("records");
        }

        // バージョン確認
        if (this.records.version === undefined || this.records.version != this.version) {
          console.log("records version up.")
          this.records.version = this.version;

          // 2022.4.27 データミスの復旧対応
          if (this.records.today == "2022-04-27") {
            if (this.records.codes.length > 0) {
              // 回答済み
              if (this.records.correct) {
                // 正答済み : なにもせず
              }
              else {
                // 正答していない
                this.records.challenge --     // 回答数 -1
                this.records.codes = []       // 回答チャレンジクリア
                this.records.correct = false  // 正答クリア (不要)
              }
            }
            else {
              // 未回答 : なにもせず
            }
          }
        }

        // 日付が変わったので未正答を精算
        const today = this.today.format("YYYY-MM-DD");
        if (this.records.today != today) {
          // 連勝をリセット
          if (this.records.codes.length > 0 && !this.records.correct) {
            this.records.streak = 0;
          }
          this.records.today = today;
          this.records.codes = [];
          this.records.correct = false;

          this.saveRecords();
        }

        // 回答を復元
        this.answers = [];
        for (const code of this.records.codes) {
          this.guessCode(code);
        }
        if (!this.records.correct && this.records.codes.length == 6) {
          this.$notify({
            group: "default",
            type: "warn",
            text:
              "答えは、" +
              this.correctItem.state +
              this.correctItem.name +
              " でした。",
            duration: -1,
          });
        }
      }
    },
    saveRecords() {
      // ローカルストレージへ保存
      const parsedRecords = JSON.stringify(this.records);
      localStorage.setItem("records", parsedRecords);
    },
    async copyToClipboard() {
      if (navigator.clipboard) {

        let shareText = `#市区町村 #ShiKuChoSon No.${this.todayNo}`

        if (this.records.correct) {
          shareText += ` ${this.records.codes.length}/6 (100%)`
        }
        else {
          const rate = this.calcRate(this.answers.slice(-1)[0])
          shareText += ` X/6 (${rate}%)`
        }
        shareText += "\n"

        for (const answer of this.answers) {
          shareText += this.rateBlock(this.calcRate(answer))
          shareText += this.dirEmoji(answer, this.today)
          shareText += "\n"
        }

        shareText += "https://shikuchoson.jp"

        try {
          await navigator.clipboard.writeText(shareText);
        } catch (err) {
          console.error("Failed to copy.", err);
        }

        this.$notify({
          group: "default",
          type: "normal",
          text: "結果をクリップボードにコピーしました。",
        });
      }
    },
  },
};
</script>
